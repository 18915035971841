<template>
  <button
    type="button"
    class="btn btn-danger btn-sm m-2"
    @click="deleteError()"
  >
    Delete All Errors
  </button>
  <table class="table table-sm table-hover">
    <thead>
      <tr>
        <th scope="col" style="width: 10%">#</th>
        <th scope="col" style="width: 10%">Error</th>
        <th scope="col" style="width: 80%">Info</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="error in errors" :key="error">
        <th scope="row">{{ error.id }}</th>
        <td>{{ error.error }}</td>
        <td>{{ error.info }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  computed: {
    errors () {
      return this.$store.state.allErrors
    },
  },
  mounted () {
    this.$store.dispatch('getErrors')
  },
  methods: {
    async deleteError () {
      await this.$store.dispatch('deleteError')
        .then(() => this.$store.dispatch('getErrors'))
    }
  }
}
</script>